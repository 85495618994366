import { Directive, inject, signal, WritableSignal } from '@angular/core';
import { AppUserService } from 'features/user/services/app-user.service';
import { finalize, Observable, startWith, tap } from 'rxjs';
import { UserInfoResponse } from 'core/fapi/models/user-info-response';

@Directive({
  selector: '[fidUser]',
  standalone: true,
  exportAs: 'user',
})
export class UserDirective {
  private appUser: AppUserService = inject(AppUserService);

  public user$: Observable<UserInfoResponse | null> = this.appUser.user$.pipe(
    startWith(null),
    tap(() => this.isLoading.set(true)),
    finalize(() => this.isLoading.set(false)),
  );

  public isLoading: WritableSignal<boolean> = signal(false);
}
